import React from 'react'

import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import FadeIn from 'react-lazyload-fadein'

import Header from '../components/Header'
import Layout from '../layouts'

const Isotope = typeof window !== `undefined` ? require('isotope-layout') : null

function ProjectPage({ location, data }) {
  const { edges: projects } = data.allMarkdownRemark
  const { header, section } = data.markdownRemark.frontmatter
  const [isClicked, setClick] = React.useState(false)
  const [isotope, setIsotope] = React.useState(null)
  const [filterKey, setFilterKey] = React.useState('*')

  React.useEffect(() => {
    if (Isotope) {
      setIsotope(
        new Isotope('.filter-container', {
          itemSelector: '#filter-item',
          layoutMode: 'fitRows',
        })
      )
    }
  }, [])

  React.useEffect(() => {
    if (isotope) {
      filterKey === '*'
        ? isotope.arrange({ filter: `*` })
        : isotope.arrange({ filter: `.${filterKey}` })
    }
  }, [isotope, filterKey])

  const items = [
    { id: 0, name: 'Tout voir', value: '*' },
    { id: 1, name: 'Logistique', value: 'logistique' },
    { id: 2, name: 'Agroalimentaire', value: 'agroalimentaire' },
    { id: 3, name: 'Commerce', value: 'commerce' },
    { id: 4, name: 'Tertiaire', value: 'tertiaire' },
    { id: 5, name: 'Concession', value: 'concession' },
    { id: 6, name: 'En cours', value: 'workinprogress' },
  ]

  const btnFilter = items.map((item) => (
    <button
      key={item.id}
      id={isClicked ? "": "btn-filter-active"}
      className={isClicked === item.id ? 'btn-filter active' : 'btn-filter'}
      onClick={() => setFilterKey(item.value) + setClick(item.id)}
    >
      {item.name}
    </button>
  ))

  return (
    <Layout location={location}>
      <div className="animPage">
        <Header title={<span>{header.title}</span>} />
        <section id="one" className="main style1 special">
          <div className="container" style={{ maxWidth: '80%' }}>
            {btnFilter}
            <hr />
          </div>
          <div className="grid-wrapper filter-container">
            {projects.map(({ node }) => (
              <div
                key={node.id}
                className={
                  node.frontmatter.type +
                  ' ' +
                  node.frontmatter.workinprogress +
                  ' col-4 cardMobile-project'
                }
                id={'filter-item'}
              >
                <FadeIn>
                  {(onload) => (
                    <Link to={node.frontmatter.path}>
                      <div className="project">
                        <div className="overlay">
                          <span>Voir</span>
                          <span>{node.frontmatter.title}</span>
                          <div className="bannerPlaces">
                            <p>{node.frontmatter.paragraph}</p>
                          </div>
                          {node.frontmatter.workinprogress ===
                            'workinprogress' && (
                            <div className="progressTriangle">
                              <p
                                style={{
                                  position: 'relative',
                                  top: '-42px',
                                  right: 10,
                                }}
                              >
                                En cours
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="cardProject">
                          <img
                            src={node.frontmatter.images[0]}
                            onLoad={onload}
                            className="imgProject"
                            alt={node.frontmatter.title}
                          />
                        </div>
                      </div>
                    </Link>
                  )}
                </FadeIn>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}
ProjectPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
export default ProjectPage

export const projectPageQuery = graphql`
  query ProjectPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        header {
          title
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-template" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            images
            type
            workinprogress
            paragraph
          }
        }
      }
    }
  }
`
